.Marketing {
  height: 100%;
}
.Marketing img.cam {
  width: 100%;
}

.Marketing .section-title.row {
  height: 40px;
  padding-top: 4px;
  text-align: left;
  margin-bottom: 20px;
}

.Marketing .subsection-title {
  padding: 10px 0;
}

.Marketing .small-image-card {
  width: 15vh;
  display: block;
  margin: 15px auto 0 auto;
}
@media (min-width: 62em) {
    .Marketing .card-columns {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
    }
}
.Marketing .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #282c34;
}
.Marketing .nav-item a {
    color: #282c34;
    text-decoration: none;
    background-color: transparent;
}