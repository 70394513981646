.AccountEdit .spacer {
  height: 0;
  margin-top: 0.4rem;
}
.AccountEdit .table td, 
.AccountEdit .table th {
  border: none;
}
.AccountEdit .account-edit-panel .field-label {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}
.AccountEdit .cancel-save {
  margin-left: 10px;
}
.AccountEdit .modal-footer {
  justify-content: space-between;
}