.Cal {
  height: 100%;
}
.Cal .section-title {
  margin-top: 9px;
  margin-left: 0.5rem;
}
.Cal .table {
  text-align: center;
}
/*.Cal .calendar-ribbon {
  height: 46px;
  overflow: hidden;
}
.Cal .calendar {
  position: absolute;
  top: 191px;
  bottom: 0;
  left: 0;
  right: 0;
}
.Cal .buttons,
.Cal .title {
  padding-top: 3px;
}
.Cal .buttons .btn {
  float: right;
  margin-left: 5px;
}
.Cal .rbc-btn-group > button {
  cursor: pointer;
}
.Cal .rbc-toolbar-label {
  font-size: 20px;
  font-weight: bold;
}
.Cal .rbc-event-content {
  font-size: 13px;
  font-weight: bold;
}
.Cal .rbc-agenda-time-cell {
  color: #FFFFFF;
  font-size: 13px;
}
.Cal .rbc-agenda-event-cell {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
}
.Cal .rbc-agenda-date-cell {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
}*/