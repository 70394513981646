.FilesDrop  {
  margin-bottom: 1rem;
}
.FilesDrop {
  padding: 12px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}
.FilesDrop .thumbscontainer {
  display: flex;
  overflow-x: auto;
  align-items: center;
}
.FilesDrop .thumbscontainer::-webkit-scrollbar {    
  display: none;
}
.FilesDrop .thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
.FilesDrop .dropzone {
  width: 90px;
  height: 90px;
  border: none;
  text-align: center;
  cursor: pointer;
}
.FilesDrop .dropzone:hover {
  background-color: #f1f1f1;
}
.FilesDrop .dropzone .plus {
  font-size: 30px;
  margin-top: 30px;
}
.FilesDrop .thumb img {
  display: block;
  width: auto;
  height: 100%;
}
.FilesDrop .thumbinner {
  display: flex;
  min-width: 0;
  overflow: hidden;
  position: relative;
}
.FilesDrop .thumbinner .ribbon {
  position: absolute;
  top: 0;
  left: auto;
  bottom: auto;
  right: 0;
  width: 100%;
  color: white;
  background-color: #21b799;
  opacity: 0;
  transition: opacity ease 400ms;
  text-align: right;
  padding-right: 4px;
}
.FilesDrop .thumbinner:hover .ribbon {
  opacity: 0.8;
}
.FilesDrop .thumbinner .ribbon .trash {
  cursor: pointer;
}




