.App {
}
.App .navbar {
  min-height: 40px;
  background-color: #282c34;
}
.App .navbar-brand {
  padding: 0;
}
.App .navbar-brand .version {
  border: 1px solid;
  border-radius: 3px;
  padding: 1px 4px;
  margin-left: 5px;
  font-size: 13px;
}
.App .new-version-reload {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.App .new-version-reload .btn {
  font-size: 0.85rem;
}
.App .navbar-brand a {
  color: #a3a3a3;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
}
.App .navbar-brand a:hover {
  text-decoration: none;
}
.App .navbar-brand a .icon.logo {
  height: 20px;
  margin-top: -4px;
  margin-right: 3px;
}
.App .navbar .user-menu {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
.App .navbar .user-menu a {
  font-size: 14px;
  color: #333;
}
.App .navbar .user-menu a.nav-link {
  padding: 0;
  color: #a3a3a3;
}
.App .navbar .user-menu .dropdown-menu {
  padding: .25rem 0;
}
.App .navbar .user-pic {
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  vertical-align: middle;
}
.App .navbar .user-pic img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
