.ProductEdit .spacer {
  height: 0;
  margin-top: 0.4rem;
}
.ProductEdit .table.noborder td, 
.ProductEdit .table.noborder th {
  border: none;
}
.ProductEdit .product-edit-panel .field-label {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}
.ProductEdit .product-edit-panel .field-value {
  font-size: 14px;
}
.ProductEdit .product-edit-panel .form-check-label {
  font-size: 12px;
}
.ProductEdit .cancel-save {
  margin-left: 10px;
}
.ProductEdit .modal-footer {
  justify-content: space-between;
}
.ProductEdit .form-control-file {
    font-size: 12px;
}
.ProductEdit .vendors-list .list-group-item {
  padding: .2rem .5rem;
  font-size: 13px;
  cursor: pointer;
}
.ProductEdit .vendors-list .price {
  text-align: right;
}
.ProductEdit .vendors-head .rgt {
  text-align: right;
}
.ProductEdit .vendors-section .btn-sm {
  padding: .15rem .3rem;
  font-size: .78rem;
}
.ProductEdit .vendor-new-form {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.ProductEdit .vendor-new-form .action-buttons {
  justify-content: space-between;
  display: flex;
}
.ProductEdit .table td {
  vertical-align: middle;
}
.ProductEdit .manufacturing-section .btn-lg {
  padding-top: 0;
  padding-bottom: 0;
}


.ProductEdit .table-bordered thead {
  background-color: #ececec;
  color: #6f6f6f;
}
.ProductEdit .table-bordered th {
  font-size: 11px;
  padding: 0 6px;
}
.ProductEdit .table-bordered td.qty {
  text-align: center;
}
.ProductEdit .table-bordered td.qty input {
  width: 50px;
  margin: 0 auto;
  text-align: center;
}
.ProductEdit .table-bordered td.tools {
  color: #b1b1b1;
}
.ProductEdit .table-bordered td.tools svg:hover {
  color: #212529;
}
.ProductEdit .table-bordered td.tools .btn,
.ProductEdit .table-bordered td.tools svg {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.ProductEdit .table-bordered td.no {
  text-align: center;
  color: grey;
}
.ProductEdit .table-bordered td, 
.ProductEdit .table-bordered th {
  vertical-align: middle;
}
.ProductEdit .table-bordered td {
  font-size: 12px;
  padding: .2rem .3rem;
}
.ProductEdit .table-bordered {
  margin-bottom: 0;
}
.ProductEdit .record.val.products {
  margin-bottom: 0.8rem;
}
.ProductEdit .showassyprods.btn-light:not(:disabled):not(.disabled).active {
  background-color: #ffffff;
}