html, body, #root, .App {
  height: 100%;
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  margin-top: -4px;
}
.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

 /* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
