.Products {
  height: 100%;
}
.Products .ReactTable {
  min-height: 660px;
  position: absolute;
  top: 84px;
  bottom: 0;
  left: 0;
  right: 0;
}
.Products .ReactTable .rt-thead.-filters {
  overflow: hidden;
}
.Products .archived,
.Products .archived .multi-count {
  /*background-color: #ddd;*/
  color: #c7c7c7;
}
.Products .product-ribbon {
  margin-top: 4px;
}
.Products .columns {
  float: right;
  margin-left: 8px;
}
.Products .columns .dropdown-item-text {
  padding: 0.1rem 0.8rem;
  font-size: 13px;
}
.Products .columns .custom-control {
  cursor: pointer;
}
.Products .newproduct {
  float: right;
  margin-left: 8px;
}
.Products .product-filters .section-title {
  float: left;
  margin-bottom: 0;
}
.Products .section-title {
  padding-right: 15px;
  padding-left: 15px;
}
.Products .section-title .search-index-wrap {
  margin-bottom: 0;
}
.Products .search-index-wrap.form-group {
  margin-bottom: 0;
}
.Products .product-filters .total-count {
  display: inline-block;
  float: left;
  width: 40px;
  font-weight: bold;
  font-size: 18px;
  color: #a3a3a3;
  text-align: center;
  border-bottom: 1px solid #a3a3a3;
  margin: 0 10px;
  padding: 2px 0;
  line-height: 24px;
}