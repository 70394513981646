.ContactEdit .spacer {
  height: 0;
  margin-top: 0.4rem;
}
.ContactEdit .table td, 
.ContactEdit .table th {
  border: none;
}
.ContactEdit .contact-edit-panel .field-label {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}
.ContactEdit .cancel-save {
  margin-left: 10px;
}