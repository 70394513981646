.Traffic {
  height: 100%;
  background-color: #000;
}
.Traffic img.cam {
  width: 100%;
}

.Traffic .section-title.row {
  height: 40px;
  padding-top: 4px;
  text-align: center;
  color: #fff;
}