.Home {
  height: 100%;
  overflow: auto;
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: center;
  align-items: center;
  background-color: #282c34;
}
.App-logo {
  height: 15vmin;
  pointer-events: none;
  margin-bottom: 1vmin;
}
.App-header {
  text-align: center;
  justify-content: center;
  color: white;
}
.App-header h1 {
  color: #a3a3a3;
  font-size: calc(20px + 2vmin);
}
.App-link {
  color: #61dafb;
}

.main-cats {
  margin-top: 30px;
}
.main-cats .cat {
  margin-bottom: 4vmin;
  cursor: pointer;
}
.main-cats .cat.disabled,
.main-cats .cat.disabled:hover {
  color: #5a6371;
  cursor: default;
}
.main-cats .cat:hover {
  color: #61dafb;
}
.main-cats .icon {
  font-size: calc(20px + 2vmin);
  margin-bottom: 0.5vmin;
}
.main-cats .label {
  font-size: calc(12px + 0.6vmin);
}
.external-links {
  margin-top: 4vmin;
  font-size: calc(12px + 0.4vmin);
}
