.CuatroCAdmin .list-group-item {
  position: relative;
  display: block;
  padding: .25rem .75rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  min-height: 34px;
}
.CuatroCAdmin .list-group-item.chosen {
  background-color: #f0edff;
}
.CuatroCAdmin .list-group {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: .75rem 1.25rem;
  margin-top: 1rem;
  background-color: #f4f4f4;
}
.CuatroCAdmin .list-group.non-assigned {
  background-color: #fff;
}
.CuatroCAdmin .group-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0;
  min-height: 32px;
}
.CuatroCAdmin .group-onlineid {
  color: grey;
  font-size: 12px;
  margin-bottom: 0.4rem;
}
.CuatroCAdmin .header-tools {
  margin-top: 0.3rem;
}
.CuatroCAdmin .group-tools .btn-toolbar,
.CuatroCAdmin .item-tools .btn-toolbar,
.CuatroCAdmin .header-tools .btn-toolbar {
  justify-content: flex-end;
}
.CuatroCAdmin .list-group .group-tools,
.CuatroCAdmin .list-group-item .item-tools {
  display: none;
}
.CuatroCAdmin .list-group .group-tools .delete {
  margin-right: 1rem;
  color: grey;
}
.CuatroCAdmin .list-group:hover .group-tools,
.CuatroCAdmin .list-group-item:hover .item-tools {
  display: block;
}
.CuatroCAdmin .list-group .group-tools.editactive  {
  display: block;
}
.CuatroCAdmin .item-tools .btn-link {
  padding: 0;
  color: #c1c1c1;
}
.CuatroCAdmin .item-tools .btn-link:hover {
  color: #424242;
}
/**/
.CuatroCAdminEditItem .cancel-save.btn-toolbar .save,
.CuatroCAdminEditColl .cancel-save.btn-toolbar .save {
  margin-left: 10px;
}
.CuatroCAdminEditItem .delete-remove .delete,
.CuatroCAdminEditColl .delete-remove .delete {
  margin-right: 10px;
}
.CuatroCAdminEditItem .delete-remove.btn-toolbar,
.CuatroCAdminEditColl .delete-remove.btn-toolbar {
  justify-content: flex-start;
}
.CuatroCAdminEditItem .cancel-save.btn-toolbar,
.CuatroCAdminEditColl .cancel-save.btn-toolbar {
  justify-content: flex-end;
}
.CuatroCAdminEditItem .table.noborder td, 
.CuatroCAdminEditItem .table.noborder th {
  border: none;
}
.CuatroCAdminEditItem .field-label {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}
.CuatroCAdminEditItem .field-value {
  font-size: 14px;
}
.CuatroCAdminEditItem .variants-values .delete {
  color: #ddd;
}
.CuatroCAdminEditItem .variants-values .delete:hover {
  color: #424242;
}
.CuatroCAdminEditItem .addmore {
  text-align: center;
}
.CuatroCAdminEditItem .addmore .btn {
  font-size: 12px;
}
.CuatroCAdminEditItem .tabs {
  margin-bottom: .7rem;
}
.CuatroCAdminEditItem .tabs .card-header {
  cursor: pointer;
  padding: .25rem .75rem;
}
.CuatroCAdminEditItem .tabs .card-body .title {
  margin-bottom: .45rem;
}
.CuatroCAdminEditItem .tabs .card-body .delete {
  font-size: 12px;
}
.CuatroCAdminEditItem .variants-values .table {
  margin-bottom: 0;
}
.CuatroCAdminEditItem .edit-value {
  text-align: center;
}
.CuatroCAdminEditItem .edit-value .cancel {
  margin-top: 1rem;
  margin-right: 1rem;
}
.CuatroCAdminEditItem .edit-value .save {
  margin-top: 1rem;
}
.CuatroCAdminEditItem .disabled {
  font-size: 14px;
  margin-left: 0.8rem;
}

























