.purchase-details .container.top {
}
.purchase-details .external-link {
  margin-left: 20px;
  font-size: 18px;
}
.purchase-details .external-link svg {
  margin-top: -3px;
}
.purchase-details .record {
  padding: 3px 0 3px 3px;
  font-size: 14px;
}
.purchase-details .record.name {
  font-weight: 600;
  text-align: right;
  padding-right: 14px;
}
.purchase-details .record.name.notes svg.enabled {
  background-color: yellow;
}
.purchase-details .record.name.po,
.purchase-details .record.val.po {
  font-size: 18px;
}
.purchase-details .record.val.amount {
  color: darkred;
  font-weight: 600;
}
.purchase-details .record.val.customer {
  font-size: 18px;
  line-height: 20px;
}
.purchase-details .record.val.notes textarea {
  height: 55px;
}
.purchase-details img.flag-icon {
  height: 25px;
  margin: -4px 15px 0 0;
  opacity: 0.8;
  border-radius: 3px;
}
.purchase-details .shipment.container {
  margin-bottom: 12px;
}
.purchase-details .order-process-form {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.purchase-details .form-label.order-process-title {
  display: block;
  font-weight: 600;
  font-size: 16px;
}
.purchase-details .order-confirmation {
  padding: 6px 0px 6px 10px;
  margin: 8px 0;
  /*background-color: #eaeaea;*/
  max-width: 184px;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #c7c7c7;
}
.purchase-details .form-group {
  margin-bottom: 0.2rem;
}
.purchase-details .form-label {
  margin-bottom: 0.2rem;
  font-size: 14px;
}
.purchase-details .order-process-confirmations {
  padding: 0 5px;
  margin: 0 auto;
}
.purchase-details .tracking-group {
  margin-top: -60px;
}
.purchase-details .order-process-confirmations .form-check {
  font-size: 12px;
  margin: 0 0 10px 0;
  padding: 6px 10px 6px 10px;
  /*background-color: #eaeaea;*/
  border-radius: 4px;
  max-width: 137px;
  min-height: 48px;
  font-weight: 500;
  border: 1px solid #c7c7c7;
}
.purchase-details .form-check-label {
  margin-left: 3px;
}
.purchase-details .order-process-confirmations .arrow {
  margin: -4px 5px 0 5px;
  font-size: 24px;
  color: #868686;
}
.popover {
  max-width: 100%;
}
.popover button.close {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 2;
}
.popover .DayPicker-Day {
  line-height: 18px;
}
.purchase-details .form-row {
  margin-left: 0;
  margin-right: 0;
}
.purchase-details .list-group-item {
  padding: .25rem 0.5rem;
  font-size: 14px;
}
.purchase-details .poc-background {
  position: absolute;
  font-size: 50px;
  top: 65px;
  right: 20px;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  color: #ffffff;
  height: 100px;
  width: 100px;
  background-color: #ececec;
  border-radius: 50%;
  line-height: 99px;
  text-align: center;
}
.purchase-details .purchase-overall-status {
  position: absolute;
  right: 50px;
  top: 18px;
}
.purchase-details .delete-trackid,
.purchase-details .delete-invoicenum,
.purchase-details .delete-shipdate,
.purchase-details .delete-deliverdate {
  float: right;
  cursor: pointer;
  display: none;
}
.purchase-details .trackid:hover .delete-trackid,
.purchase-details .invoicenum:hover .delete-invoicenum,
.purchase-details .shipdate:hover .delete-shipdate,
.purchase-details .deliverdate:hover .delete-deliverdate {
  display: block;
}
.purchase-details .pending-tag {
  height: 8px;
  width: 8px;
  margin-right: 4px;
  margin-bottom: 2px;
  background-color: #dc3545;
  display: inline-block;
  border-radius: 50%;
}
.purchase-details .make-warning {
  background-color: #fff3cd;
  color: #000;
}
.purchase-details .make-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.purchase-details .save-order {
  margin-left: 10px;
}
.purchase-details .add-partner-ref {
  font-size: 13px;
}
.purchase-details .record.val a {
  color: #afafaf;
}
.purchase-details .table-bordered th {
  font-size: 11px;
  padding: 0 6px;
}
.purchase-details .table-bordered td.qty {
  text-align: center;
}
.purchase-details .table-bordered td.no {
  text-align: center;
  color: grey;
}
.purchase-details .table-bordered td, 
.purchase-details .table-bordered th {
  vertical-align: middle;
}
.purchase-details .table-bordered td {
  font-size: 12px;
  padding: .2rem .3rem;
}
.purchase-details .table-bordered tr.hide {
  display: none;
}
.purchase-details .table-bordered {
  margin-bottom: 0;
}
.purchase-details .expand-products {
    text-align: center;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    background-color: #ececec;
    width: 120px;
    margin: 0 auto;
    cursor: pointer;
}
.purchase-details .table-bordered thead {
  background-color: #ececec;
  color: #6f6f6f;
}
.purchase-details .record.name.products {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  margin-bottom: 0.2rem;
}
.purchase-details .record.val.products {
  margin-bottom: 0.8rem;
  padding: 0;
}
.purchase-details .purchase-nav {
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  z-index: 1;
}
.purchase-details .purchase-nav .btn {
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: #888888;
  padding: .2rem .2rem;
}
.purchase-details .purchase-nav .next {
  margin-left: 0.3rem
}