.spinner {
  border: 4px solid #a3a3a3;
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.sorder-total-count {
  display: inline-block;
  float: left;
  width: 40px;
  font-weight: bold;
  font-size: 18px;
  color: #a3a3a3;
  text-align: center;
  border-bottom: 1px solid #a3a3a3;
  margin: 0 10px;
  padding: 2px 0;
  line-height: 24px;
}

.Sales {
  height: 100%;
}
.sales-ribbon {
  /*height: 46px;*/
  overflow: hidden;
}
.sales-filters {
  margin-top: 9px;
}
.sales-filters .section-title {
  float: left;
  margin-bottom: 0;
}
.sales-filters .filter-toolbar,
.sales-filters .table-refresh,
.sales-filters .table-filter-show,
.sales-filters .search-by-num {
  float: left;
  margin-right: 10px;
}
.sales-filters .table-refresh {
  margin-left: 15px;
}
.sales-filters .btn-light {
  border-color: #dedede;
  font-weight: 600;
}
.sales-filters .btn-light:not(:disabled):not(.disabled).active, 
.sales-filters .btn-light:not(:disabled):not(.disabled):active, 
.sales-filters .show>.btn-light.dropdown-toggle {
  color: #ffffff;
  background-color: #a3a3a3;
  border-color: rgb(100, 100, 100);
}
.sales-filters .btn {
  font-size: .775rem;
}
.search-by-num .form-group {
  margin-bottom: 0;
}
.search-by-num .form-control-sm {
  height: calc(1.65rem + 2px);
}
.Sales .ReactTable {
  min-height: 700px;
  position: absolute;
  top: 191px;
  bottom: 0;
  left: 0;
  right: 0;
}
.ReactTable .rt-thead {
  overflow-y: scroll;
}
.ReactTable .rt-tbody .rt-tr {
  cursor: pointer;
}
.ReactTable .rt-tr:first .rt-td {
  background-color: yellow;
}
.ReactTable div.rt-td {
  padding: 3px 5px;
  font-size: 12px;
}
.ReactTable div.rt-thead.-header,
.ReactTable div.rt-thead.-filters .rt-th {
  background-color: #e4e4e4;
  box-shadow: none;
  border-bottom: 1px solid rgba(130, 130, 130, 0.39);
}

.ReactTable .rt-thead div.rt-th {
  font-size: 12px;
  font-weight: bold;
  white-space: unset;
  padding: 4px 2px;
}
.ReactTable .rt-thead.-headerGroups {
  display: none;
}
.ReactTable div.rt-thead .rt-th,
.ReactTable div.rt-table .rt-tbody .rt-td,
.ReactTable div.rt-thead.-filters .rt-th  {
  border-right: 1px solid rgba(130, 130, 130, 0.39);
}
.ReactTable .pagination-bottom {
  font-size: 12px;
}
.ReactTable .pagination-bottom .-pagination {
  box-shadow: none;
}
.ReactTable div.-pagination input, 
.ReactTable div.-pagination select {
  padding: 2px 4px;
}
.ReactTable .pending-cell {
  width: 100%;
  height: 100%;
  background-color: rgba(255,0,0,0.35);
  color: #fff;
}
.ReactTable .multi-count {
  color: red;
}
.ReactTable .notready {
  background: rgba(255,0,0,0.35);
}
.ReactTable div.rt-table .rt-tbody .rt-td .notes svg {
  background: yellow;
}
.ReactTable div.rt-tbody {
  overflow-x: hidden;
}
.ReactTable div.rt-thead.-header, 
.ReactTable div.rt-thead.-filters .rt-th {
  overflow: hidden;
}

@media (max-width: 839px) {
  .Sales .ReactTable {
    top: 285px;
  }
}
@media (max-width: 534px) {
  .Sales .ReactTable {
    top: 448px;
  }
}
@media (max-width: 288px) {
  .Sales .ReactTable {
    top: 490px;
  }
}










