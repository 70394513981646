
.CRM .react-autosuggest__suggestions-container div {
  cursor: pointer;
}
.CRM .react-autosuggest__suggestions-container--open {
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 100%;
  border: 1px solid grey;
}
.CRM .react-autosuggest__input {
  width: 100%;
}
.CRM .autosuggest {
  font-weight: bold;
}
.CRM .nav-item {
  font-weight: 700;
  font-size: 1rem;
  color: grey;
}
.CRM .add-note {
  margin-bottom: 15px;
}
.CRM .update-email {
  font-size: 10px;
  margin: 0;
  display: none;
}
.CRM .updated-email {
  color: blue;
  font-size: 12px;
  margin: 0;
}
.CRM .search-index-wrap {
  margin-bottom: 0;
}







.CRM {
  height: 100%;
}
.CRM .main {
  padding-bottom: 80px;
}
.CRM .section-title {
  margin-top: 9px;
}
.CRM .page-title {
  text-align: left;
}
.CRM .add-account-toolbar {
  text-align: right;
  display: block;
  margin-top: 4px;
}
.CRM em {
  font-weight: bold;
}
.CRM .metadata {
  color: #545454 !important;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
  clear: both;
  margin-bottom: 18px;
}
.CRM .account-notes .metadata {
  display: inline;
  margin-left: 8px;
  margin-bottom: 0;
}
.CRM .metadata p {
  margin-bottom: 0;
}
.CRM .account-fields .metadata {
  float: left;
}
.CRM .search-bar {
  margin-bottom: 0!important;
}
.CRM .search-icon {
  text-align: right;
}
.CRM .search-feedback {
  padding: 0 15px 6px 0;
  font-size: 12px;
  position: absolute;
  overflow: auto;
  z-index: 10;
}
.CRM .search-feedback em {
  color: green;
}
.CRM .field-label {
  width: 15%;
  min-width: 100px;
  color: grey;
  text-align: right;
}
.CRM .account-panel,
.CRM .contact-panel {
  margin-top: 15px;
  font-size: 13px;
}
.CRM .account-fields,
.CRM .contact-fields {
  font-weight: bold;
}
.CRM .hit-name {
  font-size: 14px;
  font-weight: bold;
}
.CRM .hit-name .contact-account {
  font-weight: normal;
  margin-left: 8px;
}
.CRM .section-header {
  font-weight: 700;
  margin-bottom: 15px;
  color: grey;
}
.CRM .type-select {
  width: 90%;
  float: left;
}
.CRM .search-active {
  width: 10%;
  float: left;
}
.CRM .search-active .spinner {
  display: block;
  margin: 8px auto 0 8px;
}
.CRM .contact-list,
.CRM .note-list {
  clear: both;
}
.CRM .contact-list .list-group-item,
.CRM .note-list .list-group-item {
  cursor: pointer;
  padding: 0.6em 0.85em;
  overflow: auto;
}
.CRM .contact-list .list-group-item:hover,
.CRM .note-list .list-group-item:hover {
  background-color: #efefef;
}
.CRM .contact-details-title {
  margin-top: 10px;
  margin-left: 16px;
  color: grey;
  font-weight: bold;
}
.CRM .add-contact,
.CRM .account-edit,
.CRM .contact-edit,
.CRM .add-note {
  float: right;
}
.CRM .section-header {
  float: left;
}
.CRM .tools-wrap {
  padding-right: 15px;
}
.CRM .account-fields .bordered-container {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: 10px 15px; 
  overflow: auto;
}
.CRM .account-history {
   margin-top: 12px;
}
.CRM .account-history .list-group-item {
  padding: 0;
}
.CRM .account-history .metadata-fields {
  overflow: auto;
  border-bottom: 1px solid #e8e8e8;
  padding: 6px 6px 0 6px;
  background-color: #f1f1f1;
}
.CRM .account-history .from-field {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 3px 6px;
  margin-right: 6px;
}
.CRM .account-history .to-field {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 3px 6px;
  margin-right: 6px;
}
.CRM .account-history .arrow-field {
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 6px;
  font-size: 15px;
}
.CRM .account-history .fromName {

}
.CRM .account-history .fromEmail {
  font-weight: normal;
}
.CRM .account-history .toName {

}
.CRM .account-history .toEmail {
  font-weight: normal;
}
.CRM .account-history .calendar-field {
  display: inline-block;
  margin-right: 6px;
  font-weight: 600;
}
.CRM .account-history .subject-field {
  display: inline-block;
  font-size: 15px;
  padding: 3px 0 6px 0;
}
.CRM .account-history .body-field {
  padding: 6px 8px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
}
.CRM .account-history .body-field .content {
  max-height: 80px;
  overflow-y: hidden;
}
.CRM .account-history .body-field.show .content {
  max-height: 100%;
  overflow-y: auto;
}
.CRM .account-history .body-field .showmore {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding-top: 5px;
}
.CRM .account-history .body-field:hover .showmore {
  display: block;
}
.CRM .account-history .body-field.show:hover .showmore {
  display: none;
}

.CRM .account-notes,
.CRM .account-contacts {
  margin-top: 20px;
}
.CRM .import-acct-num {
  max-width: 80px;
}


