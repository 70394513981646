.ContactDetails .spacer {
  height: 0;
  margin-top: 0.4rem;
}
.ContactDetails .contact-panel {
  font-size: 13px;
}
.ContactDetails .contact-fields {
  font-weight: bold;
}
.ContactDetails .field-label {
  width: 15%;
  min-width: 100px;
  color: grey;
  text-align: right;
}
.ContactDetails .contact-details-title {
  font-weight: 700;
  color: grey;
}
.ContactDetails .metadata {
  margin-bottom: 18px;
  float: left;
  clear: both;
}
.ContactDetails .metadata p {
  color: #545454 !important;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 0;
}
.ContactDetails .modal-footer {
  justify-content: space-between;
}