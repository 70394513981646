.InvoicingAR {
  height: 100%;
}
.InvoicingAR .section-title.row {
  height: 40px;
  padding-top: 4px;
  text-align: left;
  margin-bottom: 20px;
}
.InvoicingAR img {
  max-width: 200px;
}